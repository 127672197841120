import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  imprimante1,
  imprimante2,
  imprimante4,
  imprimante5,
  imprimante6,
  imprimante7,
  imprimante8,
  imprimante9,
  encre1,
  encre2,
  encre3,
  encre4,
  ruban1,
  ruban2,
  ruban3,
  ruban4,
  bac1,
  bac2,
  bac3,
  bac4,
  pdf1,
  ///////////////////////////
  IMPRIMANTE_PANTUM_CP2200DW,
  IMPRIMANTE_PANTUM_BM5100FDW,
  IMPRIMANTE_PANTUM_M6609N,
  IMPRIMANTE_PANTUM_P3300DN,
  IMPRIMANTE_PANTUM_P3300DW,
  IMPRIMANTE_PANTUM_P2509W,
  IMPRIMANTE_PANTUM_BP5100DN,
  IMPRIMANTE_PANTUM_M6559N,
  IMPRIMANTE_PANTUM_P2509,

  hp1,
  hp2,
  hp3,

  ricoh1,
  ricoh2,
  ricoh3,
  ricoh4,
  ricoh5,
  espson1,
  espson2,
} from "../assets/images/index";

import "./style.css";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Services",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },
    {
    _id: 1004,
    title: "FAQs",
    link: "faq",
  },
  // {
  //   _id: 1005,
  //   title: "Journal",
  //   link: "/journal",
  // },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img: imprimante1,
    productName: "imprimante",
    price: "350.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "202",
    img: imprimante2,
    productName: "imprimante",
    price: "180.00",
    color: "Gray",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "203",
    img: imprimante4,
    productName: "imprimante",
    price: "250.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "204",
    img: imprimante5,
    productName: "imprimante",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "205",
    img: imprimante6,
    productName: "imprimante",
    price: "250.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "206",
    img: imprimante7,
    productName: "imprimante",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "207",
    img: imprimante8,
    productName: "imprimante",
    price: "250.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "208",
    img: imprimante9,
    productName: "imprimante",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "209",
    img: encre1,
    productName: "Keyboard 239",
    price: "250.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "210",
    img: encre2,
    productName: "Keyboard 239",
    price: "200.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "211",
    img: encre3,
    productName: "Keyboard 4321",
    price: "150.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "212",
    img: encre4,
    productName: "Keyboard 223",
    price: "180.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "213",
    img: ruban1,
    productName: "Ruban",
    price: "250.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  },
  {
    _id: "214",
    img: ruban2,
    productName: "Ruban",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  },
  {
    _id: "215",
    img: ruban3,
    productName: "Ruban",
    price: "250.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  },
  {
    _id: "216",
    img: ruban4,
    productName: "Ruban",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  },
  // {
  //   _id: "217",
  //   img: bac1,
  //   productName: "Bac de dechet",
  //   price: "25.00",
  //   color: "Mixed",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   cat: "Bac",
  // },
  // {
  //   _id: "219",
  //   img: bac2,
  //   productName: "Bac de dechet",
  //   price: "220.00",
  //   color: "Black",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   cat: "Bac",
  // },
  // {
  //   _id: "220",
  //   img: bac3,
  //   productName: "Bac de dechet",
  //   price: "25.00",
  //   color: "Mixed",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   cat: "Bac",
  // },
  // {
  //   _id: "221",
  //   img: bac4,
  //   productName: "Bac de dechet",
  //   price: "220.00",
  //   color: "Black",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   cat: "Bac",
  // },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: "201",
    img: IMPRIMANTE_PANTUM_CP2200DW,
    productName: "Content Creation",
    price: "150 /hr",
    color: "Developing engaging posts, including text, images, videos, and other formats, to resonate with the target audience. This often involves planning content calendars around specific themes, promotions, or events.",
    badge: true,
    brand: "Pantum",
    des: "Imprimante Laser PANTUM Couleur - Fonctions: Impression  - Technologie d'impression: Laser - Format Papier: A4 - Vitesse d’impression(Couleur/N&B): 24 ppm (A4) / 26 ppm (Lettre) - Résolution d'impression: 600 x 600 dpi - Sortie papier: 100 page - Mémoire: Double cœur, 1 GHz - Impression recto verso: Automatique - Heure de la première impression: Moins de 11s  - Connecteurs: USB 2.0 haut débit Ethernet 10/100/1000 BaseTX (RJ-45) 802.11b/g/n Sans fil - Dimensions: 411.2 x 394.1 x 243.7mm - Poids: 16,1 kg - Couleur: Black",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "Technology ", value: "Electrophotographic monochrome laser " },
      { label: "Print speed ", value: "22 ppm (A4)/23 ppm (Letter)" },

      { label: "First print out time ", value: "Less than 7.8s       " },
      { label: "Maximum Monthly Duty Cycle", value: "15,000 pages " },
      {
        label: "Recommended monthly volume Resolution(dpi) ",
        value: "700 pages ",
      },
      { label: "Printer language Duplex Mode ", value: "Max. 1,200×1,200 " },
      { label: "Printer  ", value: "Max. 1,2   " },
      { label: "galass  ", value: "Max. 1,2   " },
    ],
  },
  {
    _id: "202",
    img: IMPRIMANTE_PANTUM_BM5100FDW,
    productName: "Scheduling & Posting",
    price: "100 /hr",
    color: "Using tools to schedule posts for optimal times based on audience activity. Consistent posting ensures that the brand stays relevant and visible",
    badge: true,
    brand: "Pantum",
    des: "Pantum BM5100fdw Imprimante laser mono : Pantum BM5100fdw -Imprimante :laser -monochrome multifonction,:format A4-,4-en-1 avec fonctions impression, copie, numérisation et fax, vitesse d'impression de 40ppm, connexion Réseau :LAN RJ45-, Wifichrome multifonction, format A4, 4-en-1 avec fonctions impression, copie, numérisation et fax, vitesse d'impression de 40ppm, connexion Réseau LAN RJ45, Wifi",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "Vitesse d'impression ", value: "40ppm(A4)/42ppm(lettre) " },
      { label: "Temps de sortie de la première page ", value: "≤6.9 s" },

      {
        label: "Nombre de pages mensuel recommandé",
        value: "750 à 4,000 Pages",
      },
      { label: "Résolution d'impression", value: "Max.1,200x1,200 dpi" },
      {
        label: "Langage d'impression ",
        value: "PCL5e, PCL6, PS        ",
      },
      { label: "Vitesse du processeur", value: "1.2 GHz" },
      { label: "Mémoire", value: "512 MB" },
      {
        label: "Panneau de commande ",
        value: "LCD 2 lignes ||  Écran tactile 3,5 pouces      ",
      },
      { label: "Impression recto-verso automatique      ", value: "Oui" },
      {
        label: "Autres fonctions d'impression      ",
        value:
          "Impression brochure, impression sécurisée, impression disque USB Compatible AirPrint, Mopria, APP mobile (APP iOS/App Android)",
      },
      { label: "Vitese de copie ", value: "40ppm(A4)/42ppm(lettre)" },
      {
        label: "Temps de sortie de la première page",
        value: "Plateau : moins de 10 s ADF : moins de 11 s",
      },
    ],
  },

  {
    _id: "203",
    img: IMPRIMANTE_PANTUM_BP5100DN,
    productName: "Community Engagement",
    price: "180 /hr",
    color: "Actively interacting with followers by responding to comments, messages, and mentions. This builds trust, loyalty, and a sense of community around the brand.",
    badge: true,
    brand: "Pantum",
    des: "Imprimante Monochrome Laser PANTUM BP5100DN : Fonctions Impression - :Capacité Bac à papier 250 pages - :Formats papier -: A4 - Technologie d’impression Laser Monochrome - :Vitesse d’impression Noir & Black Jusqu’à 40 pages par minute en A4 -: Résolution 1200 dpi -: Mémoire 512 Mo - Connectivité Ethernet, USB 2.0 -:Auto-Duplex-, Network-Ready : Impression silencieuse - faible encombrement et respectueux de l'environnement -: cartouches à haut rendement en option - processeur haute vitesse 1,2 GHz - Dimensions: 364 x 344 x 257 mm - Poids: 9.3 kg - Garantie: 1 an",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "Vitesse d'impression ", value: "40ppm(A4)/42ppm(lettre) " },
      { label: "Temps de sortie de la première page ", value: "≤6.9 s" },

      {
        label: "Nombre de pages mensuel recommandé",
        value: "750 à 4,000 Pages",
      },
      { label: "Résolution d'impression", value: "Max.1,200x1,200 dpi" },
      {
        label: "Langage d'impression ",
        value: "PCL5e, PCL6, PS        ",
      },
      { label: "Vitesse du processeur", value: "1.2 GHz" },
      { label: "Mémoire", value: "512 MB" },
      {
        label: "Panneau de commande ",
        value: "LCD 2 lignes ||  Écran tactile 3,5 pouces      ",
      },
      { label: "Impression recto-verso automatique      ", value: "Oui" },
      {
        label: "Autres fonctions d'impression      ",
        value:
          "Impression brochure, impression sécurisée, impression disque USB Compatible AirPrint, Mopria, APP mobile (APP iOS/App Android)",
      },
      { label: "Vitese de copie ", value: "40ppm(A4)/42ppm(lettre)" },
      {
        label: "Temps de sortie de la première page",
        value: "Plateau : moins de 10 s ADF : moins de 11 s",
      },
    ],
  },
  {
    _id: "2005",
    img: IMPRIMANTE_PANTUM_M6609N,
    productName: "Analytics & Reporting",
    price: "130 /hr",
    color: "Monitoring performance metrics like engagement, reach, impressions, and conversions. Analytics help understand what content works best and where improvements are needed.",
    badge: true,
    brand: "Pantum",
    des: "Imprimante Laser 3en1 à toner rechargeable PANTUM M6559N - Capacité d'impression Jusqu'à 1600 pages - Vitesse d’impression : 22 ppm (A4) / 23 ppm (Lettre) - Résolution (impression, copie et numérisation) : 1200×1200dpi - Heure de la première impression : Moins de 7.8s - Connectivité : USB 2.0 haute vitesse - Vitesse de copie : 22cpm (A4) / 23cpm (Lettre) - Capacité du chargeur automatique de documents : 35 pages - Entrée papier : 150 pages - Sortie papier : 100 pages - Dimensions : 417 x 305 x 301 mm - Poids : 8.5 kg - Garantie 1 an",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "gtin	", value: "M6559N      " },
      { label: "Marque	", value: "PANTUM      " },
      { label: "fonctions	", value: "Monofonction      " },
      { label: "Technologie d impression		", value: "Laser      " },
      { label: "Impression	", value: "Noir & Black      " },
      { label: "Vitesse du Processeur		", value: "600MHz      " },
      {
        label: "Capacité papier		",
        value: "Capacité standard: 1 600 pages      ",
      },
      { label: "Gestion d entrée Papier Standard		", value: "150 pages      " },
      { label: "Premiére Page Imprimée		", value: "Moins de 7.8s      " },
      { label: "Cycle d utilisation Mensuel		", value: "15 000 pages      " },
      {
        label: "Résolution d impression		",
        value: "Max. 1 200 × 1 200 ppp      ",
      },
      {
        label: "Résolution d'impression Noir		",
        value: "Max. 1 200 × 1 200 ppp      ",
      },
      { label: "formats		", value: "A4      " },
      { label: "Sortie papier		", value: "100 pages      " },
      {
        label: "Vitesse de Copie Noir Black		",
        value: "22 ppm (A4) / 23 ppm (Lettre)      ",
      },
      { label: "Poids		", value: "4,75 kg      " },
    ],
  },

  // {
  //   _id: "20005",
  //   img: IMPRIMANTE_PANTUM_M6559N,
  //   productName: "HeadPhone 59N",
  //   price: "27",
  //   color: "Black",
  //   badge: true,
  //   brand: "Pantum",
  //   des: "Imprimante Laser 3en1 à toner rechargeable PANTUM M6559N - Capacité d'impression Jusqu'à 1600 pages - Vitesse d’impression : 22 ppm (A4) / 23 ppm (Lettre) - Résolution (impression, copie et numérisation) : 1200×1200dpi - Heure de la première impression : Moins de 7.8s - Connectivité : USB 2.0 haute vitesse - Vitesse de copie : 22cpm (A4) / 23cpm (Lettre) - Capacité du chargeur automatique de documents : 35 pages - Entrée papier : 150 pages - Sortie papier : 100 pages - Dimensions : 417 x 305 x 301 mm - Poids : 8.5 kg - Garantie 1 an",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [
  //     { label: "gtin	", value: "M6559N      " },
  //     { label: "Marque	", value: "PANTUM      " },
  //     { label: "fonctions	", value: "Monofonction      " },
  //     { label: "Technologie d impression		", value: "Laser      " },
  //     { label: "Impression	", value: "Noir & Black      " },
  //     { label: "Vitesse du Processeur		", value: "600MHz      " },
  //     {
  //       label: "Capacité papier		",
  //       value: "Capacité standard: 1 600 pages      ",
  //     },
  //     { label: "Gestion d entrée Papier Standard		", value: "150 pages      " },
  //     { label: "Premiére Page Imprimée		", value: "Moins de 7.8s      " },
  //     { label: "Cycle d utilisation Mensuel		", value: "15 000 pages      " },
  //     {
  //       label: "Résolution d impression		",
  //       value: "Max. 1 200 × 1 200 ppp      ",
  //     },
  //     {
  //       label: "Résolution d'impression Noir		",
  //       value: "Max. 1 200 × 1 200 ppp      ",
  //     },
  //     { label: "formats		", value: "A4      " },
  //     { label: "Sortie papier		", value: "100 pages      " },
  //     {
  //       label: "Vitesse de Copie Noir Black		",
  //       value: "22 ppm (A4) / 23 ppm (Lettre)      ",
  //     },
  //     { label: "Poids		", value: "4,75 kg      " },
  //   ],
  // },

  {
    _id: "206",
    img: IMPRIMANTE_PANTUM_P3300DN,
    productName: "Advertising & Promotion",
    price: "120 /hr",
    color: "Running paid ad campaigns to reach a broader or specific audience. Paid campaigns help achieve quicker results and target precise demographics based on interests, behaviors, and other factors.",
    badge: true,
    brand: "Pantum",
    des: "Imprimante Laser PANTUM P3300DN - Fonctions: Impression - Technologie d'impression: Laser - Format Papier: A4-A5 - Vitesse d’impression: 33 ppm (A4) / 35 ppm (Lettre) - Résolution d'impression: Jusqu'à 1200 x 1200 ppp - Papier Bac d'alimentation: 250 pages - Sortie papier: 150 page - Mémoire: 256 Mo - Impression recto verso: Automatique - Heure de la première impression: Moins de 8.2s  - Taille du support: A4, A5, JIS B5, IS0 B5, A6, Lettre, Légal, Exécutif, Folio, Oficio, Déclaration, Carte postale japonaise, ZL, Big 16K, Big 32K, 16K, 32K, B6， Yougata4, Carte postale, Younaga3, Nagagata3, Yougata2 - Connecteurs: USB2.0 haut débit / Ethernet：IEEE 802.3 10/100Base-Tx - Dimensions: 354 x 334 x 232mm - Poids: 6,8 kg - Couleur: Black",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "gtin	", value: "P3300DN      " },
      { label: "fonctions	", value: "Monofonction      " },
      { label: "Mémoire	", value: "256MO      " },
      { label: "Technologie d impression		", value: "laser      " },
      { label: "Impression		", value: "Noir & Black      " },
      { label: "Connectivite		", value: "Réseau      " },
      { label: "Vitesse du Processeur		", value: "350 MHz      " },
      { label: "Type ecran		", value: "LCD 2 lignes      " },
      {
        label: "Consommation d énergie		",
        value:
          "Impression : 525 W en moyenne Veille : 50 W en veille : moins de 2 W      ",
      },
      {
        label: "Gestion d entrée Papier Standard		",
        value: "Papier Bac d'alimentation: 250pages      ",
      },
      { label: "Premiére Page Imprimée		", value: "Moins de 8,2 secondes      " },
      {
        label: "Vitesse d impression Noir		",
        value: "33 ppm (A4) / 35 ppm (Lettre)      ",
      },
      { label: "Cycle d utilisation Mensuel		", value: "60000pages      " },
      {
        label: "Résolution d impression		",
        value: "1 200 x 1 200 dpi (maximum)      ",
      },
      { label: "formats	", value: "A4      " },
      {
        label: "Connecteurs		",
        value: "USB2.0 haut débit / Ethernet：IEEE 802.3 10/100Base-Tx      ",
      },
      { label: "Fonctionnalités		", value: "Impression      " },
      {
        label: "Volume de Pages Mensuel Recommandé		",
        value: "750 à 3 500 pages      ",
      },
      { label: "Dimensions		", value: "354 x 334 x 232mm      " },
      { label: "Garantie	", value: "1ANS      " },
    ],
  },

  {
    _id: "207",
    img: IMPRIMANTE_PANTUM_P3300DW,
    productName: "Strategy Development",
    price: "80 /hr",
    color: "Creating a roadmap that aligns social media goals with broader business objectives. This may include defining target audiences, key messages, and choosing the right platforms.",
    badge: true,
    brand: "Pantum",
    des: "Imprimante Laser PANTUM P3300DW - Fonctions: Impression - Technologie d'impression: Laser - Format Papier: A4-A5 - Vitesse d’impression: 33 ppm (A4) / 35 ppm (Lettre) - Résolution d'impression: Jusqu'à 1200 x 1200 ppp - Papier Bac d'alimentation: 250 pages - Sortie papier: 150 page - Mémoire: 256 Mo - Impression recto verso: Automatique - Heure de la première impression: Moins de 8.2s - Interface: Ethernet USB 2.0 haut débit : Wi-Fi IEEE 802.3 10/100Base-Tx : IEEE 802.11b/g/n - Connecteurs: USB ; WIFI - Dimensions: 354 x 334 x 232mm - Poids: 6,8 kg - Couleur: Black",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "gtin		", value: "P3300DW      " },
      { label: "fonctions	", value: "Monofonction      " },
      { label: "Mémoire	", value: "256 Mo      " },
      { label: "Technologie d impression		", value: "Laser      " },
      { label: "Connectivite	", value: "WIFI      " },
      { label: "Vitesse du Processeur		", value: "350 MHz      " },
      { label: "Type ecran		", value: "LCD 2 lignes      " },
      {
        label: "Consommation d énergie		",
        value:
          "Impression : 525 W en moyenne Veille : 50 W en veille : moins de 2 W      ",
      },
      {
        label: "Gestion d entrée Papier Standard		",
        value: "Papier Bac d'alimentation: 250pages      ",
      },
      { label: "Premiére Page Imprimée		", value: "Moins de 8,2 secondes      " },
      {
        label: "Vitesse d impression Noir		",
        value: "33 ppm (A4) / 35 ppm (Lettre)      ",
      },
      { label: "Cycle d utilisation Mensuel		", value: "60000pages      " },
      { label: "Résolution d impression		", value: "Max.1200x1200 dpi      " },
      {
        label: "Volume de Pages Mensuel Recommandé		",
        value: "750 à 3 500 pages      ",
      },
      {
        label: "Interface Réseau	",
        value:
          "Ethernet USB 2.0 haut débit : Wi-Fi IEEE 802.3 10/100Base-Tx : IEEE 802.11b/g/n      ",
      },
      {
        label: "Contenu de L'emballage		",
        value:
          "Imprimante - Cordon d’alimentation - Câble d’interface USB - Plateau de sortie - CD-ROM - Guide de configuration rapide      ",
      },
      { label: "Garantie	", value: "1 ANS      " },
    ],
  },

  // {
  //   _id: "208",
  //   img: IMPRIMANTE_PANTUM_P2509W,
  //   productName: "HeadPhone 509W",
  //   price: "19",
  //   color: "Black",
  //   badge: true,
  //   brand: "Pantum",
  //   des: "Imprimante Laser PANTUM P2509W - Fonctions: Impression - Technologie d'impression: Laser - Format Papier: A4 - Vitesse d’impression: 22 ppm (A4) / 23 ppm (Lettre) - Résolution d'impression: Jusqu'à 1200 x 1200 ppp - Entrée papier: de 150 feuilles - Sortie papier: 100 page - Mémoire: 128 Mo - Impression recto verso: Manuel - Heure de la première impression: Moins de 7.8s - Taille du support: A4, A5, A6, JIS B5, ISO B5, B6, Lettre, Légal, Exécutif, Déclaration, Enveloppe Monarch, Enveloppe DL, enveloppe C5, enveloppe C6, enveloppe NO.10, carte postale japonaise, Folio, Oficio, Grand 16k, 32k, 16k, Grand 32k, ZL,Yougata4, Carte postale, Younaga3, Nagagata3, Yougata2 - Toner rechargeable - Connecteurs: USB 2.0 ; Wi-Fi - Dimensions: 337 x 220 x 178 mm - Poids: 4,75 kg - Couleur: Black.",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [
  //     { label: "gtin	", value: "P2509W      " },
  //     { label: "fonctions	", value: "Monofonction      " },
  //     { label: "Technologie d impression		", value: "Laser      " },
  //     { label: "Ecran Tactile		", value: "Non      " },
  //     { label: "Connectivite	", value: "WiFi      " },
  //     { label: "Vitesse du Processeur		", value: "600MHz      " },
  //     {
  //       label: "Capacité papier		",
  //       value: "Capacité standard: 1 600 pages      ",
  //     },
  //     { label: "Premiére Page Imprimée		", value: "Moins de 7.8s      " },
  //     { label: "Cycle d utilisation Mensuel		", value: "15 000 pages      " },
  //     {
  //       label: "Résolution d impression		",
  //       value: "Max. 1 200 × 1 200 ppp      ",
  //     },
  //     { label: "formats	", value: "A4      " },
  //     { label: "Connecteurs		", value: "USB 2.0, Wi-Fi      " },
  //     {
  //       label: "Vitesse de Copie Noir Black		",
  //       value: "22 ppm (A4) / 23 ppm (Lettre)      ",
  //     },
  //     {
  //       label: "Contenu de L'emballage		",
  //       value:
  //         "Imprimante - Cordon d’alimentation - Câble d’interface USB - Plateau de sortie - CD-ROM - Guide de configuration rapide      ",
  //     },
  //     { label: "Dimensions		", value: "337 x 220 x 178 mm      " },
  //   ],
  // },
  // {
  //   _id: "233",
  //   img: IMPRIMANTE_PANTUM_P2509,
  //   productName: "HeadPhone 509",
  //   price: "35",
  //   color: "Pink",
  //   badge: true,
  //   brand: "Pantum",
  //   des: "Imprimante Laser PANTUM P2509 - Fonctions Impression - Capacité d'impression Jusqu'à :1600 pages - Formats papier: A4 - Technologie d’impression Laser Monochrome - Vitesse d’impression Noir & Black: Jusqu’à 22 pages par minute en A4 - Résolution: 1200 dpi - Mémoire: 128 Mo - Connectivité: USB 2.0 - Dimensions: 337 x 220 x 178 mm - Poids: 4.75 kg - Toner recheargeable - Garantie: 1 an",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [],
  // },
  // {
  //   _id: "220",
  //   img: bac3,
  //   productName: "Bac de dechet",
  //   price: "25.00",
  //   color: "Mixed",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   cat: "Bac",
  // },
  // {
  //   _id: "221",
  //   img: bac4,
  //   productName: "Bac de dechet",
  //   price: "220.00",
  //   color: "Black",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   cat: "Bac",
  // },
  {
    _id: "215",
    img: ruban3,
    productName: "MEVN",
    price: "290 /hr",
    color: "The MEVN stack combines MongoDB, Express.js, Vue.js, and Node.js, forming a versatile and efficient JavaScript-based framework. It’s ideal for developers who want a simpler yet flexible front-end option, as Vue.js provides an easy-to-learn structure for creating interactive and performant web applications.",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  
  },
  {
    _id: "216",
    img: ruban4,
    productName: "Django",
    price: "190 /hr",
    color: "Django is a high-level Python web framework that promotes rapid development and clean, pragmatic design. Known for its built-in admin panel, security features, and scalability, Django is widely used for data-driven applications, content management systems, and large-scale web applications.",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  },
  {
    _id: "209",
    img: encre1,
    productName: "React Native",
    price: "400 /hr",
    color: "React Native is a popular open-source framework developed by Facebook that allows developers to build mobile applications using JavaScript and React. It enables the creation of natively rendered apps for iOS and Android from a single codebase, significantly reducing development time and cost. With its rich ecosystem and robust community support, React Native offers a seamless user experience, allowing for fast performance and interactive interfaces that feel like native applications.",
    badge: true,
    des: "An input device that allows you to type letters, numbers, and symbols into your computer or other electronic device. It usually has a set of keys arranged in a specific layout, such as QWERTY",
    cat: "Encre",
  },
  {
    _id: "210",
    img: encre2,
    productName: "Flutter",
    price: "700 /hr ",
    color: "Flutter is an open-source UI software development kit created by Google for building natively compiled applications for mobile, web, and desktop from a single codebase. It uses the Dart programming language and offers a rich set of pre-designed widgets, enabling developers to create visually attractive and highly responsive applications with ease. Flutter's hot reload feature allows for quick iteration, making it an excellent choice for rapid application development.",
    badge: true,
    des: "An input device that allows you to type letters, numbers, and symbols into your computer or other electronic device. It usually has a set of keys arranged in a specific layout, such as QWERTY",
    cat: "Encre",
  },

  {
    _id: "211",
    img: encre3,
    productName: "Android Studio",
    price: "100 /hr",
    color: "Android Studio is the official integrated development environment (IDE) for Android app development, powered by IntelliJ IDEA. It provides a comprehensive suite of tools, including a code editor, debugging tools, and an emulator, to streamline the development process. With features like layout editing, performance analysis, and a rich set of libraries, Android Studio allows developers to create robust, user-friendly applications for Android devices efficiently.",
    badge: true,
    des: "An input device that allows you to type letters, numbers, and symbols into your computer or other electronic device. It usually has a set of keys arranged in a specific layout, such as QWERTY",
    cat: "Encre",
  },
  {
    _id: "212",
    img: encre4,
    productName: "Full Stack Mobile App",
    price: "800 /hr",
    color: "we offer full-stack mobile app development that combines cutting-edge technologies with a user-centric design approach. Our expert team builds high-performance apps for iOS and Android, leveraging frameworks like React Native and robust back-end solutions using Node.js and Django. We ensure your app is scalable, secure, and stands out in today’s competitive market.",
    badge: true,
    des: "An input device that allows you to type letters, numbers, and symbols into your computer or other electronic device. It usually has a set of keys arranged in a specific layout, such as QWERTY",
    cat: "Encre",
  },
  {
    _id: "213",
    img: ruban1,
    productName: "MERN",
    price: "250 /hr",
    color: "The MERN stack is a full-stack JavaScript solution that combines MongoDB, Express.js, React, and Node.js. It's popular for building dynamic, responsive, and scalable single-page applications (SPAs) and web apps, with a streamlined development process thanks to JavaScript consistency across the stack.",
    badge: true,
    des: "An input device that allows you to type letters, numbers, and symbols into your computer or other electronic device. It usually has a set of keys arranged in a specific layout, such as QWERTY",
    cat: "Ruban",
  },
  {
    _id: "214",
    img: ruban2,
    productName: "MEAN",
    price: "300 /hr",
    color: "The MEAN stack is a powerful, full-stack JavaScript framework using MongoDB, Express.js, Angular, and Node.js. Known for creating robust, maintainable web applications, MEAN is particularly suitable for developing SPAs and enterprise-level applications with dynamic front-end capabilities provided by Angular.",
    badge: true,
    des: "An input device that allows you to type letters, numbers, and symbols into your computer or other electronic device. It usually has a set of keys arranged in a specific layout, such as QWERTY",
    cat: "Ruban",
  },

  // {
  //   _id: "217",
  //   img: bac1,
  //   productName: "Keyboard 43141",
  //   price: "23.00",
  //   color: "Mixed",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   cat: "Bac",
  // },
  // {
  //   _id: "219",
  //   img: bac2,
  //   productName: "Keyboard 2343",
  //   price: "17.00",
  //   color: "Black",
  //   badge: true,
  //   des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  //   cat: "Bac",
  // },

  // =================== imprimante hp =================

  {
    _id: "hp1",
    img: hp1,
    productName:
      "On Page SEO",
    price: "70 /hr",
    color: "Content , Meta Tags , URL Structure , Image Optimization",
    badge: true,
    brand: "Hp",
    des: "Desc : Imprimante Jet D'encre HP SMART TANK 516 3en1 Couleur 3YW70A - Fonction: Impression, Copie, Numérisation - Technologie d'impression: jet d'encre - Vitesse d'impression Noir : Jusqu'à 22 ppm - Vitesse d’impression Couleur : Jusqu'à 16 ppm - Résolution d'impression Couleur : 1200x1200dpi - Format Papier : A4 - Capacité Papier : 100 feuilles -  Cycle d'utilisation (mensuel, A4) : Jusqu'à 1000pages - Connectivité: USB 2.0 haute vitesse, Wi-Fi, Bluetooth LE -  Dimensions: 447 x 373 x 158 mm - Garantie: 1 an",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "DISPONIBILITÉ", value: "In stock" },
      { label: "gtin", value: "3YW70A" },
      { label: "Marque", value: "HP" },
      { label: "Destockage", value: "Non" },
      { label: "Ecran", value: "LCD" },
      {
        label: "Taille de L écran cm",
        value: "Affichage LCD 7 segments + icône 5,08 cm (2 pouces)",
      },
      { label: "Ecran Tactile", value: "Non" },
      { label: "Technologie d impression", value: "Jet d'encre" },
      { label: "fonctions", value: "Multifonction" },
      { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
      { label: "Impression", value: "Couleur" },
      { label: "formats", value: "A4" },
      { label: "Chargement de Documents", value: "Oui" },
      { label: "Vitesse d impression Noir", value: "Jusqu'à 22 ppm" },
      { label: "Vitesse d impression Couleur", value: "Jusqu'à 16 ppm" },
      { label: "FAX", value: "Non" },
      { label: "Mémoire", value: "256 Mo" },
      {
        label: "Qualité d impression Noir",
        value: "Jusqu'à 1 200 x 1 200 ppp",
      },
      {
        label: "Qualité d impression Couleur",
        value: "Jusqu'à 4 800 x 1 200 ppp",
      },
      { label: "Résolution d impression Couleur", value: "1200x1200dpi" },
      { label: "Recto/Verso", value: "Manuel" },
      { label: "Type de Scanner", value: "Scanner à Plat" },
      { label: "Connectivite", value: "USB 2.0, WiFi" },
      { label: "Capacité papier", value: "100 feuilles" },
      { label: "Cycle d utilisation Mensuel", value: "Jusqu'à 1000 pages" },
      {
        label: "Consommation électrique",
        value:
          "0,12 Watts (Arrêt manuel), 3,12 Watts (Prêt), 0,75 Watt (Veille) 5",
      },
      { label: "Grammage", value: "75 g/m²" },
      {
        label: "Contenu de L'emballage",
        value:
          "Imprimante HP Smart Tank 516, Bouteilles d'encre: 3 x HP GT53XL (noir), 3 x HP GT52 (cyan, magenta et jaune), Notice de précaution pour l'encre; Fiche pour le ré-emballage et réglementaire RoH-EAC; Cordon d'alimentation, Guide d'installation; Notice PT",
      },
      { label: "Poids", value: "5,14 kg" },
      { label: "Dimensions", value: "447 x 373 x 158 mm" },
      { label: "Couleur", value: "Noir et Bleu" },
      { label: "Garantie", value: "1 an" },
    ],
  },

  {
    _id: "hp2",
    img: hp2,
    productName: "Off-Page SEO",
    price: "80 /hr",
    color: "Acquiring high-quality, relevant backlinks from other websites, Social media engagement, while not a direct ranking factor, can drive traffic and create link-building opportunities",
    badge: true,
    brand: "Hp",
    des: "Desc : Imprimante Multifonction Jet d'encre 3 en 1 à réservoir intégré - Impression, numérisation, copie, sans fil - Résolution d'impression: Jusqu'à 4800 x 1200 dpi - Vitesse d'impression Couleur Jusqu'à 15 ppm -Vitesse d'impression N et B Jusqu'à 18 ppm - Résolution du scanner: 1200 x 1200 dpi - Format A4 - Interface USB - Bac d'alimentation de 60 feuilles - Dimensions: 525 x 310 x 158 mm - Garantie 1 an (+ Extension de Garantie 2 Ans ) + 5 Bouteilles",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [],
  },

  {
    _id: "hp3",
    img: hp3,
    productName: "Technical SEO ",
    price: "100 /hr",
    color: "Site Speed , Mobile-Friendliness , Structured Data ",
    badge: true,
    brand: "Hp",
    des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "DISPONIBILITÉ", value: "In stock" },
      { label: "gtin", value: "Z4B04A" },
      { label: "Marque", value: "HP" },
      { label: "Destockage", value: "Non" },
      { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
      { label: "fonctions", value: "Multifonction" },
      { label: "Impression", value: "Couleur" },
      { label: "Mémoire", value: "Non" },
      { label: "FAX", value: "Non" },
      { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
      { label: "Chargement de Documents", value: "Oui" },
      { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
      { label: "Vitesse du Processeur", value: "360 MHz" },
      {
        label: "Impression sans bordure",
        value: "Oui, jusqu’à 210 x 297 mm (A4)",
      },
      { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
      { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
      {
        label: "Résolution d'impression Noir",
        value: "Jusqu'à 1 200 x 1 200 DPI",
      },
      {
        label: "Résolution d impression Couleur",
        value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
      },
      {
        label: "Résolution d impression",
        value:
          "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
      },
      { label: "Type de Scanner", value: "Scanner à Plat" },
      { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
      { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
      {
        label: "Résolution de numérisation optimisée",
        value: "Jusqu’à 1200 ppp",
      },
      { label: "Recto/Verso", value: "Manuel" },
      { label: "Connectivite", value: "USB" },
      { label: "formats", value: "A4" },
      {
        label: "Premiére Page Imprimée",
        value:
          "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
      },
      {
        label: "Alimentation-courant",
        value:
          "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
      },
      {
        label: "Type de Papier pris en charge",
        value:
          "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
      },
      {
        label: "Consommation électrique",
        value:
          "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
      },
      {
        label: "Cycle d utilisation Mensuel",
        value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
      },
      {
        label: "Alimentation papier standard",
        value:
          "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
      },
      { label: "Ecran Tactile", value: "Non" },
      { label: "Type ecran", value: "7 segments et icône LCD" },
      {
        label: "Volume de Pages Mensuel Recommandé",
        value: "400 à 800 (Jusqu'à 1000 pages)",
      },
      { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
      { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
      { label: "Grammage", value: "75 g/m²" },
      {
        label: "Contenu de L'emballage",
        value:
          "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
      },
      { label: "Poids", value: "4,67 kg" },
      { label: "Couleur", value: "Noir" },
      { label: "Dimensions", value: "525 x 310 x 158 mm" },
      { label: "Garantie", value: "1 an" },
    ],
  },
  // =================== imprimante ricoh =================
  {
    _id: "ricoh1",
    img: ricoh1,
    productName: "Pay-Per-Click Advertising Management",
    price: "200 /hr",
    color: "A targeted digital marketing strategy where ads are displayed to specific audiences, and advertisers only pay when users click on their ads, maximizing budget efficiency and driving high-quality traffic to the advertiser's site.",
    badge: true,
    brand: "Ricoh",
    des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "DISPONIBILITÉ", value: "In stock" },
      { label: "gtin", value: "Z4B04A" },
      { label: "Marque", value: "HP" },
      { label: "Destockage", value: "Non" },
      { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
      { label: "fonctions", value: "Multifonction" },
      { label: "Impression", value: "Couleur" },
      { label: "Mémoire", value: "Non" },
      { label: "FAX", value: "Non" },
      { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
      { label: "Chargement de Documents", value: "Oui" },
      { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
      { label: "Vitesse du Processeur", value: "360 MHz" },
      {
        label: "Impression sans bordure",
        value: "Oui, jusqu’à 210 x 297 mm (A4)",
      },
      { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
      { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
      {
        label: "Résolution d'impression Noir",
        value: "Jusqu'à 1 200 x 1 200 DPI",
      },
      {
        label: "Résolution d impression Couleur",
        value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
      },
      {
        label: "Résolution d impression",
        value:
          "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
      },
      { label: "Type de Scanner", value: "Scanner à Plat" },
      { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
      { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
      {
        label: "Résolution de numérisation optimisée",
        value: "Jusqu’à 1200 ppp",
      },
      { label: "Recto/Verso", value: "Manuel" },
      { label: "Connectivite", value: "USB" },
      { label: "formats", value: "A4" },
      {
        label: "Premiére Page Imprimée",
        value:
          "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
      },
      {
        label: "Alimentation-courant",
        value:
          "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
      },
      {
        label: "Type de Papier pris en charge",
        value:
          "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
      },
      {
        label: "Consommation électrique",
        value:
          "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
      },
      {
        label: "Cycle d utilisation Mensuel",
        value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
      },
      {
        label: "Alimentation papier standard",
        value:
          "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
      },
      { label: "Ecran Tactile", value: "Non" },
      { label: "Type ecran", value: "7 segments et icône LCD" },
      {
        label: "Volume de Pages Mensuel Recommandé",
        value: "400 à 800 (Jusqu'à 1000 pages)",
      },
      { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
      { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
      { label: "Grammage", value: "75 g/m²" },
      {
        label: "Contenu de L'emballage",
        value:
          "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
      },
      { label: "Poids", value: "4,67 kg" },
      { label: "Couleur", value: "Noir" },
      { label: "Dimensions", value: "525 x 310 x 158 mm" },
      { label: "Garantie", value: "1 an" },
    ],
  },

  // {
  //   _id: "ricoh2",
  //   img: ricoh2,
  //   productName: "Handsfree 180DN",
  //   price: "3",
  //   color: "Black",
  //   badge: true,
  //   brand: "Ricoh",
  //   des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [
  //     { label: "DISPONIBILITÉ", value: "In stock" },
  //     { label: "gtin", value: "Z4B04A" },
  //     { label: "Marque", value: "HP" },
  //     { label: "Destockage", value: "Non" },
  //     { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
  //     { label: "fonctions", value: "Multifonction" },
  //     { label: "Impression", value: "Couleur" },
  //     { label: "Mémoire", value: "Non" },
  //     { label: "FAX", value: "Non" },
  //     { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
  //     { label: "Chargement de Documents", value: "Oui" },
  //     { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
  //     { label: "Vitesse du Processeur", value: "360 MHz" },
  //     {
  //       label: "Impression sans bordure",
  //       value: "Oui, jusqu’à 210 x 297 mm (A4)",
  //     },
  //     { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
  //     { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
  //     {
  //       label: "Résolution d'impression Noir",
  //       value: "Jusqu'à 1 200 x 1 200 DPI",
  //     },
  //     {
  //       label: "Résolution d impression Couleur",
  //       value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     {
  //       label: "Résolution d impression",
  //       value:
  //         "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     { label: "Type de Scanner", value: "Scanner à Plat" },
  //     { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
  //     { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
  //     {
  //       label: "Résolution de numérisation optimisée",
  //       value: "Jusqu’à 1200 ppp",
  //     },
  //     { label: "Recto/Verso", value: "Manuel" },
  //     { label: "Connectivite", value: "USB" },
  //     { label: "formats", value: "A4" },
  //     {
  //       label: "Premiére Page Imprimée",
  //       value:
  //         "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
  //     },
  //     {
  //       label: "Alimentation-courant",
  //       value:
  //         "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
  //     },
  //     {
  //       label: "Type de Papier pris en charge",
  //       value:
  //         "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
  //     },
  //     {
  //       label: "Consommation électrique",
  //       value:
  //         "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
  //     },
  //     {
  //       label: "Cycle d utilisation Mensuel",
  //       value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
  //     },
  //     {
  //       label: "Alimentation papier standard",
  //       value:
  //         "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
  //     },
  //     { label: "Ecran Tactile", value: "Non" },
  //     { label: "Type ecran", value: "7 segments et icône LCD" },
  //     {
  //       label: "Volume de Pages Mensuel Recommandé",
  //       value: "400 à 800 (Jusqu'à 1000 pages)",
  //     },
  //     { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
  //     { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
  //     { label: "Grammage", value: "75 g/m²" },
  //     {
  //       label: "Contenu de L'emballage",
  //       value:
  //         "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
  //     },
  //     { label: "Poids", value: "4,67 kg" },
  //     { label: "Couleur", value: "Noir" },
  //     { label: "Dimensions", value: "525 x 310 x 158 mm" },
  //     { label: "Garantie", value: "1 an" },
  //   ],
  // },
  // {
  //   _id: "ricoh3",
  //   img: ricoh3,
  //   productName: "Handsfree 520N",
  //   price: "4",
  //   color: "Black",
  //   badge: true,
  //   brand: "Ricoh",
  //   des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [
  //     { label: "DISPONIBILITÉ", value: "In stock" },
  //     { label: "gtin", value: "Z4B04A" },
  //     { label: "Marque", value: "HP" },
  //     { label: "Destockage", value: "Non" },
  //     { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
  //     { label: "fonctions", value: "Multifonction" },
  //     { label: "Impression", value: "Couleur" },
  //     { label: "Mémoire", value: "Non" },
  //     { label: "FAX", value: "Non" },
  //     { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
  //     { label: "Chargement de Documents", value: "Oui" },
  //     { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
  //     { label: "Vitesse du Processeur", value: "360 MHz" },
  //     {
  //       label: "Impression sans bordure",
  //       value: "Oui, jusqu’à 210 x 297 mm (A4)",
  //     },
  //     { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
  //     { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
  //     {
  //       label: "Résolution d'impression Noir",
  //       value: "Jusqu'à 1 200 x 1 200 DPI",
  //     },
  //     {
  //       label: "Résolution d impression Couleur",
  //       value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     {
  //       label: "Résolution d impression",
  //       value:
  //         "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     { label: "Type de Scanner", value: "Scanner à Plat" },
  //     { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
  //     { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
  //     {
  //       label: "Résolution de numérisation optimisée",
  //       value: "Jusqu’à 1200 ppp",
  //     },
  //     { label: "Recto/Verso", value: "Manuel" },
  //     { label: "Connectivite", value: "USB" },
  //     { label: "formats", value: "A4" },
  //     {
  //       label: "Premiére Page Imprimée",
  //       value:
  //         "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
  //     },
  //     {
  //       label: "Alimentation-courant",
  //       value:
  //         "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
  //     },
  //     {
  //       label: "Type de Papier pris en charge",
  //       value:
  //         "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
  //     },
  //     {
  //       label: "Consommation électrique",
  //       value:
  //         "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
  //     },
  //     {
  //       label: "Cycle d utilisation Mensuel",
  //       value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
  //     },
  //     {
  //       label: "Alimentation papier standard",
  //       value:
  //         "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
  //     },
  //     { label: "Ecran Tactile", value: "Non" },
  //     { label: "Type ecran", value: "7 segments et icône LCD" },
  //     {
  //       label: "Volume de Pages Mensuel Recommandé",
  //       value: "400 à 800 (Jusqu'à 1000 pages)",
  //     },
  //     { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
  //     { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
  //     { label: "Grammage", value: "75 g/m²" },
  //     {
  //       label: "Contenu de L'emballage",
  //       value:
  //         "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
  //     },
  //     { label: "Poids", value: "4,67 kg" },
  //     { label: "Couleur", value: "Noir" },
  //     { label: "Dimensions", value: "525 x 310 x 158 mm" },
  //     { label: "Garantie", value: "1 an" },
  //   ],
  // },
  // {
  //   _id: "ricoh4",
  //   img: ricoh4,
  //   productName: "Handsfree C840",
  //   price: "5",
  //   color: "White",
  //   badge: true,
  //   brand: "Ricoh",
  //   des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [
  //     { label: "DISPONIBILITÉ", value: "In stock" },
  //     { label: "gtin", value: "Z4B04A" },
  //     { label: "Marque", value: "HP" },
  //     { label: "Destockage", value: "Non" },
  //     { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
  //     { label: "fonctions", value: "Multifonction" },
  //     { label: "Impression", value: "Couleur" },
  //     { label: "Mémoire", value: "Non" },
  //     { label: "FAX", value: "Non" },
  //     { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
  //     { label: "Chargement de Documents", value: "Oui" },
  //     { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
  //     { label: "Vitesse du Processeur", value: "360 MHz" },
  //     {
  //       label: "Impression sans bordure",
  //       value: "Oui, jusqu’à 210 x 297 mm (A4)",
  //     },
  //     { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
  //     { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
  //     {
  //       label: "Résolution d'impression Noir",
  //       value: "Jusqu'à 1 200 x 1 200 DPI",
  //     },
  //     {
  //       label: "Résolution d impression Couleur",
  //       value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     {
  //       label: "Résolution d impression",
  //       value:
  //         "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     { label: "Type de Scanner", value: "Scanner à Plat" },
  //     { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
  //     { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
  //     {
  //       label: "Résolution de numérisation optimisée",
  //       value: "Jusqu’à 1200 ppp",
  //     },
  //     { label: "Recto/Verso", value: "Manuel" },
  //     { label: "Connectivite", value: "USB" },
  //     { label: "formats", value: "A4" },
  //     {
  //       label: "Premiére Page Imprimée",
  //       value:
  //         "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
  //     },
  //     {
  //       label: "Alimentation-courant",
  //       value:
  //         "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
  //     },
  //     {
  //       label: "Type de Papier pris en charge",
  //       value:
  //         "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
  //     },
  //     {
  //       label: "Consommation électrique",
  //       value:
  //         "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
  //     },
  //     {
  //       label: "Cycle d utilisation Mensuel",
  //       value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
  //     },
  //     {
  //       label: "Alimentation papier standard",
  //       value:
  //         "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
  //     },
  //     { label: "Ecran Tactile", value: "Non" },
  //     { label: "Type ecran", value: "7 segments et icône LCD" },
  //     {
  //       label: "Volume de Pages Mensuel Recommandé",
  //       value: "400 à 800 (Jusqu'à 1000 pages)",
  //     },
  //     { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
  //     { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
  //     { label: "Grammage", value: "75 g/m²" },
  //     {
  //       label: "Contenu de L'emballage",
  //       value:
  //         "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
  //     },
  //     { label: "Poids", value: "4,67 kg" },
  //     { label: "Couleur", value: "Noir" },
  //     { label: "Dimensions", value: "525 x 310 x 158 mm" },
  //     { label: "Garantie", value: "1 an" },
  //   ],
  // },
  // {
  //   _id: "ricoh5",
  //   img: ricoh5,
  //   productName: "Handsfree 4510D",
  //   price: "6",
  //   color: "White",
  //   badge: true,
  //   brand: "Ricoh",
  //   des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [
  //     { label: "DISPONIBILITÉ", value: "In stock" },
  //     { label: "gtin", value: "Z4B04A" },
  //     { label: "Marque", value: "HP" },
  //     { label: "Destockage", value: "Non" },
  //     { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
  //     { label: "fonctions", value: "Multifonction" },
  //     { label: "Impression", value: "Couleur" },
  //     { label: "Mémoire", value: "Non" },
  //     { label: "FAX", value: "Non" },
  //     { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
  //     { label: "Chargement de Documents", value: "Oui" },
  //     { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
  //     { label: "Vitesse du Processeur", value: "360 MHz" },
  //     {
  //       label: "Impression sans bordure",
  //       value: "Oui, jusqu’à 210 x 297 mm (A4)",
  //     },
  //     { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
  //     { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
  //     {
  //       label: "Résolution d'impression Noir",
  //       value: "Jusqu'à 1 200 x 1 200 DPI",
  //     },
  //     {
  //       label: "Résolution d impression Couleur",
  //       value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     {
  //       label: "Résolution d impression",
  //       value:
  //         "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     { label: "Type de Scanner", value: "Scanner à Plat" },
  //     { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
  //     { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
  //     {
  //       label: "Résolution de numérisation optimisée",
  //       value: "Jusqu’à 1200 ppp",
  //     },
  //     { label: "Recto/Verso", value: "Manuel" },
  //     { label: "Connectivite", value: "USB" },
  //     { label: "formats", value: "A4" },
  //     {
  //       label: "Premiére Page Imprimée",
  //       value:
  //         "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
  //     },
  //     {
  //       label: "Alimentation-courant",
  //       value:
  //         "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
  //     },
  //     {
  //       label: "Type de Papier pris en charge",
  //       value:
  //         "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
  //     },
  //     {
  //       label: "Consommation électrique",
  //       value:
  //         "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
  //     },
  //     {
  //       label: "Cycle d utilisation Mensuel",
  //       value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
  //     },
  //     {
  //       label: "Alimentation papier standard",
  //       value:
  //         "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
  //     },
  //     { label: "Ecran Tactile", value: "Non" },
  //     { label: "Type ecran", value: "7 segments et icône LCD" },
  //     {
  //       label: "Volume de Pages Mensuel Recommandé",
  //       value: "400 à 800 (Jusqu'à 1000 pages)",
  //     },
  //     { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
  //     { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
  //     { label: "Grammage", value: "75 g/m²" },
  //     {
  //       label: "Contenu de L'emballage",
  //       value:
  //         "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
  //     },
  //     { label: "Poids", value: "4,67 kg" },
  //     { label: "Couleur", value: "Noir" },
  //     { label: "Dimensions", value: "525 x 310 x 158 mm" },
  //     { label: "Garantie", value: "1 an" },
  //   ],
  // },

  // =================== imprimante ricoh =================

  {
    _id: "espson1",
    img: espson1,
    productName: "Audience Segmentation and Personalization",
    price: "110 /hr",
    color: "By segmenting your audience and personalizing content, you ensure that each subscriber receives relevant, engaging emails that resonate with their interests. This increases open rates, click-through rates, and overall effectiveness.",
    badge: true,
    brand: "Epson",
    des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "DISPONIBILITÉ", value: "In stock" },
      { label: "gtin", value: "Z4B04A" },
      { label: "Marque", value: "HP" },
      { label: "Destockage", value: "Non" },
      { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
      { label: "fonctions", value: "Multifonction" },
      { label: "Impression", value: "Couleur" },
      { label: "Mémoire", value: "Non" },
      { label: "FAX", value: "Non" },
      { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
      { label: "Chargement de Documents", value: "Oui" },
      { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
      { label: "Vitesse du Processeur", value: "360 MHz" },
      {
        label: "Impression sans bordure",
        value: "Oui, jusqu’à 210 x 297 mm (A4)",
      },
      { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
      { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
      {
        label: "Résolution d'impression Noir",
        value: "Jusqu'à 1 200 x 1 200 DPI",
      },
      {
        label: "Résolution d impression Couleur",
        value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
      },
      {
        label: "Résolution d impression",
        value:
          "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
      },
      { label: "Type de Scanner", value: "Scanner à Plat" },
      { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
      { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
      {
        label: "Résolution de numérisation optimisée",
        value: "Jusqu’à 1200 ppp",
      },
      { label: "Recto/Verso", value: "Manuel" },
      { label: "Connectivite", value: "USB" },
      { label: "formats", value: "A4" },
      {
        label: "Premiére Page Imprimée",
        value:
          "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
      },
      {
        label: "Alimentation-courant",
        value:
          "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
      },
      {
        label: "Type de Papier pris en charge",
        value:
          "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
      },
      {
        label: "Consommation électrique",
        value:
          "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
      },
      {
        label: "Cycle d utilisation Mensuel",
        value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
      },
      {
        label: "Alimentation papier standard",
        value:
          "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
      },
      { label: "Ecran Tactile", value: "Non" },
      { label: "Type ecran", value: "7 segments et icône LCD" },
      {
        label: "Volume de Pages Mensuel Recommandé",
        value: "400 à 800 (Jusqu'à 1000 pages)",
      },
      { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
      { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
      { label: "Grammage", value: "75 g/m²" },
      {
        label: "Contenu de L'emballage",
        value:
          "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
      },
      { label: "Poids", value: "4,67 kg" },
      { label: "Couleur", value: "Noir" },
      { label: "Dimensions", value: "525 x 310 x 158 mm" },
      { label: "Garantie", value: "1 an" },
    ],
  },

  {
    _id: "espson2",
    img: espson2,
    productName: "Compelling Subject Lines and Strong Call-to-Action (CTA)",
    price: "130 /hr",
    color: "A great subject line grabs attention, while a clear and enticing CTA guides recipients toward taking the desired action, whether it’s visiting your site, making a purchase, or learning more. Together, they maximize engagement and conversion.",
    badge: true,
    brand: "Epson",
    des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
    cat: "Imprimante",
    pdf: pdf1,
    ficheTech: [
      { label: "DISPONIBILITÉ", value: "In stock" },
      { label: "gtin", value: "Z4B04A" },
      { label: "Marque", value: "HP" },
      { label: "Destockage", value: "Non" },
      { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
      { label: "fonctions", value: "Multifonction" },
      { label: "Impression", value: "Couleur" },
      { label: "Mémoire", value: "Non" },
      { label: "FAX", value: "Non" },
      { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
      { label: "Chargement de Documents", value: "Oui" },
      { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
      { label: "Vitesse du Processeur", value: "360 MHz" },
      {
        label: "Impression sans bordure",
        value: "Oui, jusqu’à 210 x 297 mm (A4)",
      },
      { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
      { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
      {
        label: "Résolution d'impression Noir",
        value: "Jusqu'à 1 200 x 1 200 DPI",
      },
      {
        label: "Résolution d impression Couleur",
        value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
      },
      {
        label: "Résolution d impression",
        value:
          "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
      },
      { label: "Type de Scanner", value: "Scanner à Plat" },
      { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
      { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
      {
        label: "Résolution de numérisation optimisée",
        value: "Jusqu’à 1200 ppp",
      },
      { label: "Recto/Verso", value: "Manuel" },
      { label: "Connectivite", value: "USB" },
      { label: "formats", value: "A4" },
      {
        label: "Premiére Page Imprimée",
        value:
          "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
      },
      {
        label: "Alimentation-courant",
        value:
          "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
      },
      {
        label: "Type de Papier pris en charge",
        value:
          "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
      },
      {
        label: "Consommation électrique",
        value:
          "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
      },
      {
        label: "Cycle d utilisation Mensuel",
        value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
      },
      {
        label: "Alimentation papier standard",
        value:
          "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
      },
      { label: "Ecran Tactile", value: "Non" },
      { label: "Type ecran", value: "7 segments et icône LCD" },
      {
        label: "Volume de Pages Mensuel Recommandé",
        value: "400 à 800 (Jusqu'à 1000 pages)",
      },
      { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
      { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
      { label: "Grammage", value: "75 g/m²" },
      {
        label: "Contenu de L'emballage",
        value:
          "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
      },
      { label: "Poids", value: "4,67 kg" },
      { label: "Couleur", value: "Noir" },
      { label: "Dimensions", value: "525 x 310 x 158 mm" },
      { label: "Garantie", value: "1 an" },
    ],
  },
];
